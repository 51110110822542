(function ($) {
    var containers = {};

    $.fn.trackPromos = function () {
        var promos = $(this).find('a[data-promo],[data-promo-name],[data-promo-section] a').map(function () {
            var $this = $(this);
            if ($this.data('promo-viewed'))
                return null;
            $this.data('promo-viewed', true);

            // Determine exact location by finding closest container and counting total global instances inside this container
            var containerName = $this.data('slot')
                || $this.parent().closest('[data-promo-section]').data('promo-section')
                || $this.closest('.smidgen').data('key')
                || ($this.closest('.results-header') && 'results-header')
                || $this.closest('[data-cms-content]').data('cms-content')
                || $this.closest('[data-cms-section]').data('cms-section')
                || location.pathname + '-slot';

            var container = containers[containerName];
            if (!container) {
                containers[containerName] = container = [];
            }

            if ($this.find('img').length || !$this.closest('.slides').length) {
                container.push($this);
            }

            // Name is generic section name, i.e. "Coop-Home-Brands"
            // this will be pulled from a container div's 'data-promo-section',
            // or can be overridden using 'data-promo-name' on the a tag
            var name = $this.data('promo-name') || $this.parent().closest('[data-promo-section]').data('promo-section'),
                // id is the specific promotion, i.e. 'coop-brand-Burton'
                id = $this.data('promo'),
                // creative is the content displayed to the user, typically an image filename or link text
                creative = $this.data('creative')
                    || ($this.find('img').data('src') || $this.find('img').attr('src') || '').split('/').pop()
                    || $this.find('img').attr('alt')
                    || $this.attr('alt')
                    || $this.text(),
                position = $this.data('slot') || (containerName + '-' + container.length),
                promo = {
                    id: id,
                    name: name,
                    creative: creative,
                    position: position
                };

            // Bind click event to push ecommerce promotionClick event to UGA
            (function ($el, p) {
                $el.on('click', function () {
                    dataLayerManager.pushPromoClick(p);
                });
            })($this, promo);

            return promo;
        }).get();

        if (promos && promos.length)
            dataLayerManager.pushPromoViews(promos);
    };

    $(function () {
        $('#results-page').on('refresh.results', function () {
            containers = {};
            $(this).trackPromos();
        });		
		
        window.requestIdleCallback(function() {
            $('#wrapper').trackPromos();
        });
    });
})(jQuery);